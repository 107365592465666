import * as React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from 'react-bootstrap';
import Image from "./elements/image"

const GridImages = ({images, padding={bottom:80, top:80,left:15,right:15}}) => (
  <Container style={{padding:`${padding.top}px ${padding.right}px ${padding.bottom}px ${padding.left}px`}}>
      <Row className="mb-4">
        {
          images.map((image, i) => {
            return (
              <Col lg={i===0 ? 12 : 6} className="image-border" key={i}>
                <div className="image-border mb-2">
                  <Image fadeIn={true} imgsrc={image.image} />
                </div>
                <p>{image.text}</p>
              </Col>
            )
          })
        }
      </Row>
  </Container>
)

GridImages.propTypes = {
  images: PropTypes.array,
  padding: PropTypes.object,
}

export default GridImages