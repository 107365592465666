import * as React from "react"
// import { Link } from "gatsby"
import Scrollspy from "react-scrollspy"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import "../scss/app.scss"
import TextImage from "../components/textImage"
import GridImages from "../components/gridImages"
import HeaderImageArrowDown from "../components/headerImageArrowDown"
import TitleText from "../components/textTitle"
import BlockIntroduction from "../components/blockIntroduction"
import ReadProcessBar from "../components/readProgressBar"
import TitleTextCol from "../components/titleTextCol"
import StickyMenu from "../components/stickyMenu"
// import Image from "../components/elements/image"
import Cta from "../components/cta"
import MetaImage from "../images/featured/loyall-featured-image-rebranding-story-light.jpg"

const OurRebrandingStory = (props) => {
  const readProgressBarStoryPage = React.createRef()
  return (
    <Layout>
      <ReadProcessBar target={readProgressBarStoryPage} />
      <Seo 
        title="Het verhaal achter Loyall" 
        image={{src: MetaImage, height: 628, width: 1200}}
        description="Van vier losse labels naar één: we hebben marketing, design, development en integraties samengevoegd. Op deze manier bouwen we aan betere en sterkere bedrijven op digitaal niveau."
        pathname={props.location.pathname}      
      />
      <div className="page-wrapper" ref={readProgressBarStoryPage}>
        <BlockIntroduction
          title="The rebranding story"
          intro={
            <h4>
              Zo werden we één: het verhaal achter Loyall.
            </h4>
          }
        />
        
        <HeaderImageArrowDown
          imageBig={"loyall-rebranding-story-header-1.jpg"}
          imageSmall={"loyall-rebranding-story-header-2.jpg"}
          elementId="rebranding-story-header"
          readingTime={8}
          delay={200}
          animationDuration={800}
        />
        <TitleTextCol
          title="Ons uiterlijk is veranderd, maar niet onze normen en waarden."
          paragh1="Van origine waren we vier losse labels: SendtoDeliver (marketing), Site.nu (development), Wilde Amsterdam (design) en Hoox (integraties). Steeds vaker zagen we dat uitdagingen van onze leads en klanten niet door één label alleen opgelost kon worden. Waar we marketing inzetten, kunnen we niet zonder design en development. En dat geldt alle kanten op. Het werd tijd om de labels samen te voegen onder één brand: Loyall."
        />
        <TitleTextCol
          title="Visie"
          titleLevelHeading={5}
          columnSpan={8}
          bigParagraph={true}
          paragh1="Onze visie is om betere en sterkere bedrijven te bouwen op digitaal niveau, op een duurzame manier."
          padding={{ top: 80, bottom: 40, left: 15, right: 15 }}
        />
        <TitleTextCol
          title="Missie"
          columnSpan={8}
          titleLevelHeading={5}
          bigParagraph={true}
          paragh1="We maken jouw toekomstige klanten en kandidaten loyaal door de complete digital lifecycle van je bedrijf te verbeteren."
          padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
        />
        <TitleTextCol
          title="Kernwaarden"
          columnSpan={8}
          titleLevelHeading={5}
          customContentColumn={
            <div>
              <TitleText
                title="We zijn loyaal."
                padding={{ top: 0, bottom: 40, left: 15, right: 15 }}
                classes="bordered-bottom"
                titleLevelHeading={6}
                text="Het zit niet voor niks in onze naam. We zijn er voor de lange termijn, sterke relaties en duurzame groei. Niet alleen voor ons team, maar ook voor onze klanten."
              />
              <TitleText
                title="We stimuleren groei."
                padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                classes="bordered-bottom"
                titleLevelHeading={6}
                text="Mensen losmaken die vastgeroest zitten. Creatieve ideeën stimuleren die voor succes kunnen zorgen. We vinden het belangrijk dat we ons blijven ontwikkelen en stimuleren dat ook bij onze partners."
              />
              <TitleText
                title="We geloven in de kracht van samenwerken."
                padding={{ top: 40, bottom: 0, left: 15, right: 15 }}
                titleLevelHeading={6}
                text="Samenwerken brengt ons verder. Niet alleen door onze disciplines nog dichter bij elkaar te brengen, maar ook door samen met onze partners het verschil te maken."
              />
            </div>
          }
        />
        <StickyMenu
          padding={{ top: 80, bottom: 0, left: 15, right: 15 }}
          stickyElement={
            <div style={{paddingTop:"20px"}}>
              <h6>Inspiration & Sketches</h6>
              <Scrollspy
                offset={-120}
                className="vertical-menu-wrapper"
                items={[
                  "inspiration",
                  "schetsen",
                  "eerste-ontwerp",
                  "een-vorm-voor-allemaal",
                ]}
                currentClassName="vertical-menu-active"
              >
                <li>
                  <a href="#inspiration">Inspiratie</a>
                </li>
                <li>
                  <a href="#schetsen">Schetsen</a>
                </li>
                <li>
                  <a href="#eerste-ontwerp">Eerste ontwerp</a>
                </li>
                <li>
                  <a href="#een-vorm-voor-allemaal">Een vorm voor allemaal</a>
                </li>
              </Scrollspy>
            </div>
          }
          content={
            <div>
              <TextImage
                title="Inspiratie"
                intro=""
                paragh="Hoe breng je vier unieke labels samen en maak je hier een nieuwe business van? Een grote uitdaging als je te maken hebt met vier compleet verschillende labels met ieder hun eigen branding. Om ideeën op te doen moet je eerst inspiratie opdoen. Wat vinden we tof? Welke vormen, kleuren en stijlen passen bij ons? Hieruit ontstond een moodboard. "
                link=""
                linkText=""
                image={"loyall-rebranding-story-inspiration.jpg"}
                col={false}
                padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                id="inspiration"
              />
              {/* <TitleText title="Inspiratie" id="inspiration" text="Hoe breng je vier unieke labels samen en maak je hier een nieuwe business van? Een grote uitdaging als je te maken hebt met vier compleet verschillende labels met ieder hun eigen branding. Om ideeën op te doen moet je eerst inspiratie opdoen. Wat vinden we tof? Welke vormen, kleuren en stijlen passen bij ons? Hieruit ontstond een moodboard." padding={{top:40, bottom:0, left:0, right:0}} /> */}
              <div id="schetsen">
                <TitleText
                  title="Schetsen"
                  titleLevelHeading={4}

                  text="Vier afzonderlijke labels die samen moeten gaan werken. Hoe maak je hier een nieuwe branding van? We begonnen met de huidige branding, zoals de kleuren en logo’s. We ontdekten dat ieder label vanuit de kernboodschap duidelijk verbonden was aan een vorm."
                  padding={{ top: 40, bottom: 64, left: 15, right: 15 }}
                />
                <h6 style={{paddingLeft:15}}>Vormen</h6>
                <GridImages
                  images={[
                    {
                      image: "loyall-rebranding-story-vormen.jpg",
                      text: "",
                    },
                  ]}
                  padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                />
                <TitleText
                  title="Vierkant"
                  titleLevelHeading={6}
                  text="Sterke funderingen geven je een goede basis en iets om altijd op terug te kunnen vallen. Het vierkant staat symbool voor dat fundament en die bouwen we met ons designlabel Wilde Amsterdam."
                  classes="bordered-bottom"
                  padding={{ top: 24, bottom: 40, left: 15, right: 15 }}
                />
                <TitleText
                  title="Driehoek"
                  titleLevelHeading={6}
                  text="De driehoek geeft richting aan. Richt je de punt naar beneden, dan wordt het instabiel. Maar richt je de driehoek naar boven of naar rechts, dan staat het voor groei. Met ons marketinglabel SendtoDeliver zorgen we voor online groei."
                  classes="bordered-bottom"
                  padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                />
                <TitleText
                  title="Cirkel"
                  titleLevelHeading={6}
                  text="Een cirkel staat voor oneindigheid en verbondenheid. We zien het als een symbool dat staat voor nieuwe dingen ontdekken en de focus op het grote geheel. Dit past precies bij het developmentlabel Site.nu."
                  classes="bordered-bottom"
                  padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                />
                <TitleText
                  title="Kruis"
                  titleLevelHeading={6}
                  text="Een kruis visualiseert het samenkomen van vertrouwen en moed. Met een kruis maak je verbindingen; twee komen samen. Het kruis hoort bij ons integratielabel Hoox."
                  padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                />
              </div>
              <TextImage
                title="Eerste ontwerp"
                
                titleLevelHeading={4}
                intro=""
                paragh="Het kiezen van de vormen is de eerste stap in het ontwerpen van nieuwe designelementen. Maar met enkel een simpele vorm ben je er nog niet. Om het verder uit te werken stelden we de volgende voorwaarden: Elke vorm moest opgebouwd worden in twee delen; Tussen de elementen moest negative spacing gebruikt worden; Organische vormen moesten in het ontwerp terugkomen"
                link=""
                linkText=""
                image={"loyall-rebranding-story-eerste-ontwerp.jpg"}
                col={false}
                padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                id="eerste-ontwerp"
              />
              <TextImage
                title="Een vorm voor allemaal"
                titleLevelHeading={4}

                intro=""
                paragh="Hoe maak je van vier verschillende logo’s één nieuw logo waarin alle vormen terugkomen? Los werkten de logo’s goed, maar ze moesten ook nog samen gaan werken. Om daarvoor te zorgen haalden we uit elke vorm een element en voegden we die samen tot een geheel. In het nieuwe logo moest onze missie om de lifecycle van onze partners te versterken ook terugkomen."
                link=""
                linkText=""
                image={"loyall-rebranding-story-een-vorm-voor-allemaal.jpg"}
                col={false}
                padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                id="een-vorm-voor-allemaal"
              />
            </div>
          }
        />
        <StickyMenu
          padding={{ top: 80, bottom: 0, left: 15, right: 15 }}
          stickyElement={
            <div style={{paddingTop:"20px"}}>
              <h6>Kleuren & typografie</h6>
              <Scrollspy
                offset={-120}
                className="vertical-menu-wrapper"
                items={[
                  "kleuren",
                  "typografie",
                  "het-eindresultaat",
                ]}
                currentClassName="vertical-menu-active"
              >
                <li>
                  <a href="#kleuren">Kleuren</a>
                </li>
                <li>
                  <a href="#typografie">Typografie</a>
                </li>
                <li>
                  <a href="#het-eindresultaat">Het eindresultaat</a>
                </li>
              </Scrollspy>
            </div>
          }
          content={
            <div>
              <div id="kleuren">
               <TitleText
                  title="Kleuren"
                  titleLevelHeading={4}
                  text="De volgende stap in onze rebranding was het bepalen van onze huisstijl kleuren. Branding komt pas echt tot leven als je er kleur aan toevoegt. En iedere kleur heeft een eigen betekenis of gevoel. Branding creëer je niet door simpelweg een mooie kleur te kiezen. De kleur moet bij je identiteit passen en uitstralen wat je wilt uitstralen. Anders zend je de verkeerde boodschap uit."
                  padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                />
              <GridImages 
              padding={{ top: 24, bottom: 40, left: 15, right: 15 }}

              images={[
                {
                  image: "loyall-rebranding-story-kleuren-1.jpg",
                  text: "",
                },
                {
                  image: "loyall-rebranding-story-kleuren-2.jpg",
                  text: "Opvallend. Creativiteit. Geluk. Vernieuwend.",
                },
                {
                  image: "loyall-rebranding-story-kleuren-3.jpg",
                  text: "Passie. Krachtig. Leiderschap. Toewijding.",
                },
                {
                  image: "loyall-rebranding-story-kleuren-4.jpg",
                  text: "Vrijheid. Intelligentie. Zelfvertrouwen. Loyaal.",
                },
                {
                  image: "loyall-rebranding-story-kleuren-5.jpg",
                  text: "Groei. Harmonie. Stabiliteit. Vernieuwend.",
                },
              ]}/>
              </div>
              <div id="typografie">
                <TitleText
                  title="Typografie"
                  titleLevelHeading={4}
                  text="Onze nieuwe uitstraling is pas compleet wanneer het logo en de kleuren verrijkt worden met typografie. De stijl moest organisch aanvoelen. Speels en vrij, zonder dat het te strak of te los werd. Het lettertype moest niet alleen goed werken voor opvallende koppen op de website, maar ook samenkomen in het logo."
                  padding={{ top: 40, bottom: 64, left: 15, right: 15 }}
                />
                <GridImages
                  images={[
                    {
                      image: "loyall-rebranding-story-typografie.jpg",
                      text: "",
                    },
                  ]}
                  padding={{ top: 0, bottom: 40, left: 15, right: 15 }}
                />
              </div>
              <div id="het-eindresultaat">
               <TitleText
                  title="Het eindresultaat"
                  titleLevelHeading={4}
                  text="Na het samenvoegen van alle vormen, kleuren en het lettertype ontstaat loyall: ons moederbedrijf. Al onze labels komen terug in de cirkel boven loyall. Alle labels los hebben ook een nieuw logo met eigen kleur en vorm."
                  padding={{ top: 40, bottom: 40, left: 15, right: 15 }}
                />
              <GridImages 
              padding={{ top: 24, bottom: 40, left: 15, right: 15 }}

              images={[
                {
                  image: "loyall-rebranding-story-resultaat-1.jpg",
                  text: "",
                },
                {
                  image: "loyall-rebranding-story-resultaat-2.jpg",
                  text: "",
                },
                {
                  image: "loyall-rebranding-story-resultaat-3.jpg",
                  text: "",
                },
                {
                  image: "loyall-rebranding-story-resultaat-4.jpg",
                  text: "",
                },
                {
                  image: "loyall-rebranding-story-resultaat-5.jpg",
                  text: "",
                },
              ]}/>
              </div>
            </div>
          }
        />
        <Cta
          verticalStyling={false}
          title=""
          intro="Doelstellingen vertalen naar design, development en marketing."
          link="/discovery"
          linkText="Ontdek de mogelijkheden"
        />
      </div>
    </Layout>
  )
}
export default OurRebrandingStory
